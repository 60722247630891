define('lodash/assignWith', ['exports', 'lodash/_copyObject', 'lodash/_createAssigner', 'lodash/keys'], function (exports, _lodash_copyObject, _lodash_createAssigner, _lodashKeys) {

  /**
   * This method is like `_.assign` except that it accepts `customizer`
   * which is invoked to produce the assigned values. If `customizer` returns
   * `undefined`, assignment is handled by the method instead. The `customizer`
   * is invoked with five arguments: (objValue, srcValue, key, object, source).
   *
   * **Note:** This method mutates `object`.
   *
   * @static
   * @memberOf _
   * @since 4.0.0
   * @category Object
   * @param {Object} object The destination object.
   * @param {...Object} sources The source objects.
   * @param {Function} [customizer] The function to customize assigned values.
   * @returns {Object} Returns `object`.
   * @see _.assignInWith
   * @example
   *
   * function customizer(objValue, srcValue) {
   *   return _.isUndefined(objValue) ? srcValue : objValue;
   * }
   *
   * var defaults = _.partialRight(_.assignWith, customizer);
   *
   * defaults({ 'a': 1 }, { 'b': 2 }, { 'a': 3 });
   * // => { 'a': 1, 'b': 2 }
   */
  var assignWith = (0, _lodash_createAssigner['default'])(function (object, source, srcIndex, customizer) {
    (0, _lodash_copyObject['default'])(source, (0, _lodashKeys['default'])(source), object, customizer);
  });

  exports['default'] = assignWith;
});