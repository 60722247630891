define('ember-cli-bugsnag/utils/errors', ['exports', 'ember'], function (exports, _ember) {
  exports.getContext = getContext;
  exports.generateError = generateError;

  function getContext(router) {
    var infos = router.currentState.routerJsState.handlerInfos;

    var url = router.get('location').getURL();
    var routeName = infos[infos.length - 1].name;

    var firstSegments = routeName.replace(".index", "").replace(/\./g, ' ');
    var prettyRouteName = _ember['default'].String.capitalize(firstSegments);

    return prettyRouteName + " (" + routeName + ", " + url + ")";
  }

  function generateError(cause, stack) {
    var error = new Error(cause);
    error.stack = stack;
    return error;
  }
});