define('ember-cli-gravatar/services/gravatar', ['exports', 'ember', 'md5'], function (exports, _ember, _md5) {
  var ajax = _ember['default'].$.ajax;
  var Promise = _ember['default'].RSVP.Promise;
  var Service = _ember['default'].Service;
  exports['default'] = Service.extend({
    hasGravatar: function hasGravatar(email, secure) {
      if (!email) {
        throw new Error('expecting email');
      }

      var hash = (0, _md5['default'])(email);

      return new Promise(function (resolve) {
        ajax('http' + (secure ? 's' : '') + '://www.gravatar.com/avatar/' + hash + '?d=404', {
          complete: function complete(_ref) {
            var status = _ref.status;

            var NOT_FOUND = 404;

            resolve(status !== NOT_FOUND);
          }
        });
      });
    }
  });
});