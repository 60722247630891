define('lodash/forEach', ['exports', 'lodash/_arrayEach', 'lodash/_baseEach', 'lodash/_castFunction', 'lodash/isArray'], function (exports, _lodash_arrayEach, _lodash_baseEach, _lodash_castFunction, _lodashIsArray) {

  /**
   * Iterates over elements of `collection` and invokes `iteratee` for each element.
   * The iteratee is invoked with three arguments: (value, index|key, collection).
   * Iteratee functions may exit iteration early by explicitly returning `false`.
   *
   * **Note:** As with other "Collections" methods, objects with a "length"
   * property are iterated like arrays. To avoid this behavior use `_.forIn`
   * or `_.forOwn` for object iteration.
   *
   * @static
   * @memberOf _
   * @since 0.1.0
   * @alias each
   * @category Collection
   * @param {Array|Object} collection The collection to iterate over.
   * @param {Function} [iteratee=_.identity] The function invoked per iteration.
   * @returns {Array|Object} Returns `collection`.
   * @see _.forEachRight
   * @example
   *
   * _.forEach([1, 2], function(value) {
   *   console.log(value);
   * });
   * // => Logs `1` then `2`.
   *
   * _.forEach({ 'a': 1, 'b': 2 }, function(value, key) {
   *   console.log(key);
   * });
   * // => Logs 'a' then 'b' (iteration order is not guaranteed).
   */
  function forEach(collection, iteratee) {
    var func = (0, _lodashIsArray['default'])(collection) ? _lodash_arrayEach['default'] : _lodash_baseEach['default'];
    return func(collection, (0, _lodash_castFunction['default'])(iteratee));
  }

  exports['default'] = forEach;
});