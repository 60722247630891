define('ember-href-to/href-to', ['exports', 'ember'], function (exports, _ember) {
  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  var _default = (function () {
    function _default(applicationInstance, event) {
      _classCallCheck(this, _default);

      this.applicationInstance = applicationInstance;
      this.event = event;
      this.target = _ember['default'].$(event.currentTarget);
      this.url = this.target.attr('href');
    }

    _createClass(_default, [{
      key: 'maybeHandle',
      value: function maybeHandle() {
        if (this.shouldHandle()) {
          this.handle();
        }
      }
    }, {
      key: 'shouldHandle',
      value: function shouldHandle() {
        return this.isUnmodifiedLeftClick() && this.isNotIgnored() && this.hasNoTargetBlank() && this.hasNoActionHelper() && this.hasNoDownload() && this.isNotLinkComponent() && this.recognizeUrl();
      }
    }, {
      key: 'handle',
      value: function handle() {
        var router = this._getRouter();
        var urlWithoutRoot = this.getUrlWithoutRoot();

        router.handleURL(urlWithoutRoot);
        router.router.updateURL(urlWithoutRoot);
        this.event.preventDefault();
      }
    }, {
      key: 'isUnmodifiedLeftClick',
      value: function isUnmodifiedLeftClick() {
        var e = this.event;

        return (e.which === undefined || e.which === 1) && !e.ctrlKey && !e.metaKey;
      }
    }, {
      key: 'hasNoTargetBlank',
      value: function hasNoTargetBlank() {
        return this.target.attr('target') !== '_blank';
      }
    }, {
      key: 'isNotIgnored',
      value: function isNotIgnored() {
        return _ember['default'].isNone(this.target.attr('data-href-to-ignore'));
      }
    }, {
      key: 'hasNoActionHelper',
      value: function hasNoActionHelper() {
        return _ember['default'].isNone(this.target.attr('data-ember-action'));
      }
    }, {
      key: 'hasNoDownload',
      value: function hasNoDownload() {
        return this.target.attr('download') === undefined;
      }
    }, {
      key: 'isNotLinkComponent',
      value: function isNotLinkComponent() {
        var id = this.target[0].id;
        var componentInstance = this._getContainer(this.applicationInstance).lookup('-view-registry:main')[id];
        var isLinkComponent = componentInstance ? componentInstance instanceof _ember['default'].LinkComponent : false;

        return !isLinkComponent;
      }
    }, {
      key: 'recognizeUrl',
      value: function recognizeUrl() {
        var url = this.url;
        var didRecognize = false;

        if (url) {
          var router = this._getContainer().lookup('router:main');
          var rootUrl = this._getRootUrl();
          var isInternal = url.indexOf(rootUrl) === 0;
          var urlWithoutRoot = this.getUrlWithoutRoot();

          didRecognize = isInternal && router.router.recognizer.recognize(urlWithoutRoot);
        }

        return didRecognize;
      }
    }, {
      key: 'getUrlWithoutRoot',
      value: function getUrlWithoutRoot() {
        var url = this.url;
        var rootUrl = this._getRootUrl();
        return url.substr(rootUrl.length - 1);
      }
    }, {
      key: '_getRouter',
      value: function _getRouter() {
        return this._getContainer().lookup('router:main');
      }
    }, {
      key: '_getContainer',
      value: function _getContainer() {
        return 'lookup' in this.applicationInstance ? this.applicationInstance : this.applicationInstance.container;
      }
    }, {
      key: '_getRootUrl',
      value: function _getRootUrl() {
        var router = this._getRouter();
        var rootURL = router.rootURL;

        if (rootURL.charAt(rootURL.length - 1) !== '/') {
          rootURL = rootURL + '/';
        }

        return rootURL;
      }
    }]);

    return _default;
  })();

  exports['default'] = _default;
});