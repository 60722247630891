define('lodash/flattenDepth', ['exports', 'lodash/_baseFlatten', 'lodash/toInteger'], function (exports, _lodash_baseFlatten, _lodashToInteger) {

  /**
   * Recursively flatten `array` up to `depth` times.
   *
   * @static
   * @memberOf _
   * @since 4.4.0
   * @category Array
   * @param {Array} array The array to flatten.
   * @param {number} [depth=1] The maximum recursion depth.
   * @returns {Array} Returns the new flattened array.
   * @example
   *
   * var array = [1, [2, [3, [4]], 5]];
   *
   * _.flattenDepth(array, 1);
   * // => [1, 2, [3, [4]], 5]
   *
   * _.flattenDepth(array, 2);
   * // => [1, 2, 3, [4], 5]
   */
  function flattenDepth(array, depth) {
    var length = array == null ? 0 : array.length;
    if (!length) {
      return [];
    }
    depth = depth === undefined ? 1 : (0, _lodashToInteger['default'])(depth);
    return (0, _lodash_baseFlatten['default'])(array, depth);
  }

  exports['default'] = flattenDepth;
});