define("lodash/_baseLodash", ["exports"], function (exports) {
  /**
   * The function whose prototype chain sequence wrappers inherit from.
   *
   * @private
   */
  function baseLodash() {
    // No operation performed.
  }

  exports["default"] = baseLodash;
});