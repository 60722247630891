define('lodash/result', ['exports', 'lodash/_castPath', 'lodash/isFunction', 'lodash/_toKey'], function (exports, _lodash_castPath, _lodashIsFunction, _lodash_toKey) {

  /**
   * This method is like `_.get` except that if the resolved value is a
   * function it's invoked with the `this` binding of its parent object and
   * its result is returned.
   *
   * @static
   * @since 0.1.0
   * @memberOf _
   * @category Object
   * @param {Object} object The object to query.
   * @param {Array|string} path The path of the property to resolve.
   * @param {*} [defaultValue] The value returned for `undefined` resolved values.
   * @returns {*} Returns the resolved value.
   * @example
   *
   * var object = { 'a': [{ 'b': { 'c1': 3, 'c2': _.constant(4) } }] };
   *
   * _.result(object, 'a[0].b.c1');
   * // => 3
   *
   * _.result(object, 'a[0].b.c2');
   * // => 4
   *
   * _.result(object, 'a[0].b.c3', 'default');
   * // => 'default'
   *
   * _.result(object, 'a[0].b.c3', _.constant('default'));
   * // => 'default'
   */
  function result(object, path, defaultValue) {
    path = (0, _lodash_castPath['default'])(path, object);

    var index = -1,
        length = path.length;

    // Ensure the loop is entered when path is empty.
    if (!length) {
      length = 1;
      object = undefined;
    }
    while (++index < length) {
      var value = object == null ? undefined : object[(0, _lodash_toKey['default'])(path[index])];
      if (value === undefined) {
        index = length;
        value = defaultValue;
      }
      object = (0, _lodashIsFunction['default'])(value) ? value.call(object) : value;
    }
    return object;
  }

  exports['default'] = result;
});