define('lodash/hasIn', ['exports', 'lodash/_baseHasIn', 'lodash/_hasPath'], function (exports, _lodash_baseHasIn, _lodash_hasPath) {

  /**
   * Checks if `path` is a direct or inherited property of `object`.
   *
   * @static
   * @memberOf _
   * @since 4.0.0
   * @category Object
   * @param {Object} object The object to query.
   * @param {Array|string} path The path to check.
   * @returns {boolean} Returns `true` if `path` exists, else `false`.
   * @example
   *
   * var object = _.create({ 'a': _.create({ 'b': 2 }) });
   *
   * _.hasIn(object, 'a');
   * // => true
   *
   * _.hasIn(object, 'a.b');
   * // => true
   *
   * _.hasIn(object, ['a', 'b']);
   * // => true
   *
   * _.hasIn(object, 'b');
   * // => false
   */
  function hasIn(object, path) {
    return object != null && (0, _lodash_hasPath['default'])(object, path, _lodash_baseHasIn['default']);
  }

  exports['default'] = hasIn;
});