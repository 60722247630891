define('ember-data/-private/system/references/has-many', ['exports', 'ember', 'ember-data/-private/system/references/reference', 'ember-data/-private/debug', 'ember-data/-private/features'], function (exports, _ember, _emberDataPrivateSystemReferencesReference, _emberDataPrivateDebug, _emberDataPrivateFeatures) {
  var resolve = _ember['default'].RSVP.resolve;
  var get = _ember['default'].get;

  var HasManyReference = function HasManyReference(store, parentInternalModel, hasManyRelationship) {
    this._super$constructor(store, parentInternalModel);
    this.hasManyRelationship = hasManyRelationship;
    this.type = hasManyRelationship.relationshipMeta.type;
    this.parent = parentInternalModel.recordReference;

    // TODO inverse
  };

  HasManyReference.prototype = Object.create(_emberDataPrivateSystemReferencesReference['default'].prototype);
  HasManyReference.prototype.constructor = HasManyReference;
  HasManyReference.prototype._super$constructor = _emberDataPrivateSystemReferencesReference['default'];

  HasManyReference.prototype.remoteType = function () {
    if (this.hasManyRelationship.link) {
      return "link";
    }

    return "ids";
  };

  HasManyReference.prototype.link = function () {
    return this.hasManyRelationship.link;
  };

  HasManyReference.prototype.ids = function () {
    var members = this.hasManyRelationship.members.toArray();

    return members.map(function (internalModel) {
      return internalModel.id;
    });
  };

  HasManyReference.prototype.meta = function () {
    return this.hasManyRelationship.meta;
  };

  HasManyReference.prototype.push = function (objectOrPromise) {
    var _this = this;

    return resolve(objectOrPromise).then(function (payload) {
      var array = payload;

      if (false) {}

      var useLegacyArrayPush = true;
      if (typeof payload === "object" && payload.data) {
        array = payload.data;
        useLegacyArrayPush = array.length && array[0].data;

        if (false) {}
      }

      if (!false) {
        useLegacyArrayPush = true;
      }

      var internalModels = undefined;
      if (useLegacyArrayPush) {
        internalModels = array.map(function (obj) {
          var record = _this.store.push(obj);

          return record._internalModel;
        });
      } else {
        var records = _this.store.push(payload);
        internalModels = _ember['default'].A(records).mapBy('_internalModel');
      }

      _this.hasManyRelationship.computeChanges(internalModels);

      return _this.hasManyRelationship.getManyArray();
    });
  };

  HasManyReference.prototype._isLoaded = function () {
    var hasData = get(this.hasManyRelationship, 'hasData');
    if (!hasData) {
      return false;
    }

    var members = this.hasManyRelationship.members.toArray();

    return members.every(function (internalModel) {
      return internalModel.isLoaded() === true;
    });
  };

  HasManyReference.prototype.value = function () {
    if (this._isLoaded()) {
      return this.hasManyRelationship.getManyArray();
    }

    return null;
  };

  HasManyReference.prototype.load = function () {
    if (!this._isLoaded()) {
      return this.hasManyRelationship.getRecords();
    }

    return resolve(this.hasManyRelationship.getManyArray());
  };

  HasManyReference.prototype.reload = function () {
    return this.hasManyRelationship.reload();
  };

  exports['default'] = HasManyReference;
});