define('lodash/_listCacheSet', ['exports', 'lodash/_assocIndexOf'], function (exports, _lodash_assocIndexOf) {

  /**
   * Sets the list cache `key` to `value`.
   *
   * @private
   * @name set
   * @memberOf ListCache
   * @param {string} key The key of the value to set.
   * @param {*} value The value to set.
   * @returns {Object} Returns the list cache instance.
   */
  function listCacheSet(key, value) {
    var data = this.__data__,
        index = (0, _lodash_assocIndexOf['default'])(data, key);

    if (index < 0) {
      ++this.size;
      data.push([key, value]);
    } else {
      data[index][1] = value;
    }
    return this;
  }

  exports['default'] = listCacheSet;
});