define('ember-submit-button/components/submit-button', ['exports', 'ember', 'ember-submit-button/templates/components/submit-button'], function (exports, _ember, _emberSubmitButtonTemplatesComponentsSubmitButton) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var assert = _ember['default'].assert;
  var get = _ember['default'].get;
  var isPresent = _ember['default'].isPresent;
  var isEmpty = _ember['default'].isEmpty;
  exports['default'] = Component.extend({
    layout: _emberSubmitButtonTemplatesComponentsSubmitButton['default'],
    tagName: 'button',
    classNames: ['btn'],
    classNameBindings: ['model.isSaving:saving'],
    attributeBindings: ['isDisabled:disabled', 'type'],

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (isEmpty(get(this, 'disabled'))) {
        assert('You must pass a model into the submit-button component', this.get('model'));
      }
    },

    type: 'submit',
    savingText: 'Saving',

    text: computed('model', function () {
      var action = this.get('model.isNew') ? 'Create' : 'Update';
      var name = this.get('model.constructor.modelName');

      return action + ' ' + name;
    }),

    isDisabled: computed('disabled', 'model.isSaving', function () {
      var disabled = get(this, 'disabled');
      var isSaving = get(this, 'model.isSaving');

      return isPresent(disabled) ? disabled : isSaving;
    }),

    click: function click() {
      this.sendAction();
    }
  });
});