define('ember-cli-gravatar/components/gravatar-image', ['exports', 'ember', 'md5'], function (exports, _ember, _md5) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'img',
    attributeBindings: ['src', 'alt', 'title', 'size:height', 'size:width'],
    classNames: ['gravatar-image'],
    size: 250,
    email: '',
    title: '',
    defaultImage: '',
    secure: true,
    retina: false,
    hash: null,

    src: _ember['default'].computed('email', 'imageSize', 'default', 'hash', function () {
      var email = this.get('email');
      var imageSize = this.get('imageSize');
      var def = this.get('defaultImage');
      var secure = this.get('secure');
      var protocol = secure ? 'https' : 'http';

      var hashToUse = this.get('hash') || (0, _md5['default'])(email);
      return protocol + '://www.gravatar.com/avatar/' + hashToUse + '?s=' + imageSize + '&d=' + def;
    }),

    imageSize: _ember['default'].computed('size', 'retina', function () {
      var size = this.get('size');
      return this.get('retina') ? size * 2 : size;
    })
  });
});