define('emberx-select/components/x-option', ['exports', 'ember'], function (exports, _ember) {
  var isArray = _ember['default'].isArray;

  /**
   * Used to wrap a native `<option>` tag and associate an object with
   * it that can be bound. It can only be used in conjuction with a
   * containing `x-select` component
   *
   * @class Ember.XOptionComponent
   * @extends Ember.Component
   */
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'option',
    attributeBindings: ['selected', 'name', 'disabled', 'value', 'title'],
    classNameBindings: [':x-option'],

    /**
     * The value associated with this option. When this option is
     * selected, the `x-select` will fire its action with this
     * value.
     *
     * @property value
     * @type Object
     * @default null
     */
    value: null,

    /**
     * Property bound to the `selected` attribute of the native
     * `<option>` element. It is aware of the containing `x-select`'s
     * value and will mark itself if it is the same.
     *
     * @private
     * @property selected
     * @type Boolean
     */
    selected: _ember['default'].computed('value', 'select.value', 'select.multiple', function () {
      if (this.get('select.multiple') && isArray(this.get('select.value'))) {
        var selectValue = _ember['default'].A(this.get('select.value'));

        return selectValue.includes(this.get('value'));
      } else {
        return this.get('value') === this.get('select.value');
      }
    }),

    didReceiveAttrs: function didReceiveAttrs(_ref) {
      var _super;

      var oldAttrs = _ref.oldAttrs;

      (_super = this._super).apply.apply(_super, arguments);

      if (oldAttrs && !!oldAttrs.disabled) {
        // Undefined also means the option is not disabled.
        var oldDisabled = !!oldAttrs.disabled.value;

        if (this.get('disabled') !== oldDisabled) {
          this.sendAction('on-disable', this.get('value'), this.get('disabled'));
        }
      }
    },

    /**
     * Register this x-option with the containing `x-select`
     *
     * @override
     */
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      _ember['default'].run.scheduleOnce('afterRender', function () {
        _this.get('register')(_this);
      });
    },

    /**
     * Unregister this x-option with its containing x-select.
     *
     * @override
     */
    willDestroyElement: function willDestroyElement() {
      this.get('unregister')(this);
      this._super.apply(this, arguments);
    }
  });
});