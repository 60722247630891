define('ember-cli-bugsnag/utils/bugsnag-configuration', ['exports'], function (exports) {
  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  var BugsnagConfiguration = (function () {
    function BugsnagConfiguration(config, releaseStage) {
      _classCallCheck(this, BugsnagConfiguration);

      this.config = config || {};

      this.config.releaseStage = releaseStage;

      this._setDefaultValues();
      this.valid = this._validate();
    }

    _createClass(BugsnagConfiguration, [{
      key: 'apply',
      value: function apply(bugsnagInstance) {
        var _this = this;

        if (this.valid) {
          Object.keys(this.config).forEach(function (key) {
            bugsnagInstance[key] = _this.config[key];
          });
        } else {
          console.error('[ember-cli-bugsnag] Could not start Bugsnag reporting because of configuration issues');
        }
      }
    }, {
      key: '_validate',
      value: function _validate() {
        if (!this.config.apiKey) {
          console.error('[ember-cli-bugsnag] Required value `apiKey` was not configured. Please add it to `bugsnag.apiKey` in `config/environment` or define environment variable `BUGSNAG_API_KEY`');
          return false;
        }
        return true;
      }
    }, {
      key: '_setDefaultValues',
      value: function _setDefaultValues() {
        if (!this.config.notifyReleaseStages) {
          console.info('[ember-cli-bugsnag] Notify release stages not defined in configuration, defaulting to `["production"]`. Either define `bugsnag.notifyReleaseStages` in your config file or a comma separated environment variable BUGSNAG_NOTIFY_RELEASE');
          this.config.notifyReleaseStages = ["production"];
        }
      }
    }]);

    return BugsnagConfiguration;
  })();

  exports['default'] = BugsnagConfiguration;
});