define('ember-hypersearch/components/hyper-search', ['exports', 'ember', 'ember-hypersearch/templates/components/hyper-search'], function (exports, _ember, _emberHypersearchTemplatesComponentsHyperSearch) {
  var Component = _ember['default'].Component;
  var emberArray = _ember['default'].A;
  var _Ember$RSVP = _ember['default'].RSVP;
  var Promise = _Ember$RSVP.Promise;
  var resolve = _Ember$RSVP.resolve;
  var reject = _Ember$RSVP.reject;
  var ajax = _ember['default'].$.ajax;
  var _Ember$run = _ember['default'].run;
  var debounce = _Ember$run.debounce;
  var bind = _Ember$run.bind;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var isBlank = _ember['default'].isBlank;
  var isPresent = _ember['default'].isPresent;
  var typeOf = _ember['default'].typeOf;

  /**
   * Returns the key for the query in the cache. Only works in conjunction with
   * Ember.get.
   *
   *
   * @public
   * @param {String} query
   * @return {String} nested key name
   */
  function keyForQuery(query) {
    return '_cache.' + safeKeyString(query);
  }

  /**
   * Ensure string does not contain characters that will cause Ember.get to break
   *
   * IE: Replaces periods (.) with dashes (-)
   *
   * @public
   * @param {String} query
   * @return {String} safe key name
  */
  function safeKeyString(query) {
    return query.replace('.', '-');
  }

  exports['default'] = Component.extend({
    layout: _emberHypersearchTemplatesComponentsHyperSearch['default'],
    minQueryLength: 3,
    debounceRate: 0,
    endpoint: null,
    resultKey: null,
    placeholder: null,

    init: function init() {
      this._super.apply(this, arguments);
      this._cache = {};
      this.results = emberArray();
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.removeAllFromCache();
    },

    cache: function cache(query, results) {
      set(this, keyForQuery(query), results);
      this._handleAction('loadingHandler', false);
      return resolve(results);
    },

    getCacheForQuery: function getCacheForQuery(query) {
      return get(this, keyForQuery(query));
    },

    removeFromCache: function removeFromCache(query) {
      delete this._cache[safeKeyString(query)];
      this.notifyPropertyChange('_cache');
    },

    removeAllFromCache: function removeAllFromCache() {
      delete this._cache;
      set(this, '_cache', {});
    },

    clearResults: function clearResults() {
      get(this, 'results').clear();
      this.notifyPropertyChange('results');
    },

    fetch: function fetch(query) {
      if (isBlank(query) || query.length < get(this, 'minQueryLength')) {
        return reject();
      }

      var cachedValue = this.getCacheForQuery(query);

      this._handleAction('loadingHandler', true);

      if (isPresent(cachedValue)) {
        this._handleAction('loadingHandler', false);
        return resolve(cachedValue);
      } else {
        return this.requestAndCache.apply(this, arguments);
      }
    },

    /**
     * Override to handle the fetching of data. Must return a `Promise`.
     *
     * @public
     * @method request
     * @param {String} query
     * @return {Promise}
     */
    request: function request(query) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        ajax({
          dataType: 'json',
          method: 'GET',
          url: get(_this, 'endpoint'),
          data: { q: query }
        }).then(resolve, reject);
      });
    },

    requestAndCache: function requestAndCache(query) {
      var _this2 = this;

      return this.request(query).then(function (results) {
        return _this2.cache(query, results);
      })['catch'](function (error) {
        return reject(error);
      });
    },

    _search: function _search() {
      var value = arguments.length <= 0 || arguments[0] === undefined ? this.$('input').val() : arguments[0];

      return this.fetch(value).then(bind(this, this._setResults));
    },

    _setResults: function _setResults(results) {
      this._handleAction('handleResults', results);

      return set(this, 'results', results);
    },

    _handleAction: function _handleAction(actionName) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      if (this.attrs && typeOf(this.attrs[actionName]) === 'function') {
        var _attrs;

        (_attrs = this.attrs)[actionName].apply(_attrs, args);
      } else {
        this.sendAction.apply(this, [actionName].concat(args));
      }
    },

    actions: {
      search: function search(_event, query) {
        debounce(this, '_search', query, get(this, 'debounceRate'), true);
      },

      selectResult: function selectResult(result) {
        this._handleAction('selectResult', result);
      }
    }
  });
});